<template>
  <v-card class="step step1 px-8 pt-4 pb-8 my-4">
    <v-form v-model="step1Valid">
      <!-- District -->
      <v-autocomplete v-model="district" data-lpignore="true" :items="districtOptions" label="District"> </v-autocomplete>

      <!-- Sex -->
      <v-radio-group v-model="sex" class="mt-0 mb-0">
        <template v-slot:label>
          <div>Sex</div>
        </template>
        <v-radio label="Female" value="Female"></v-radio>
        <v-radio label="Male" value="Male"></v-radio>
        <v-radio label="Unkown" value="Unkown"></v-radio>
      </v-radio-group>

      <!-- Birthday -->
      <v-dialog ref="dialogBirthDate" v-model="birthDateDialogOpen" :close-on-content-click="false" transition="scale-transition" width="290px">
        <template v-slot:activator="{ on }">
          <v-text-field
            class="birthdaypicker-custom"
            :class="required ? 'required' : ''"
            :value="computedBirthDate"
            required
            append-icon="mdi-calendar"
            clearable
            label="Birthday"
            readonly
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker ref="picker" v-model="birthDate" :max="new Date().toISOString().substr(0, 10)" min="1900-01-01" @change="saveDate">
        </v-date-picker>
      </v-dialog>

      <!-- Age -->
      <v-text-field label="Age" disabled type="number" v-model="age"></v-text-field>

      <!-- Age category 1 -->
      <v-text-field label="Age Category 1" disabled type="text" v-model="ageCat1"></v-text-field>

      <!-- Age category 2 -->
      <v-text-field label="Age Category 2" disabled type="text" v-model="ageCat2"></v-text-field>

      <!-- Resident of tourist -->
      <v-select :items="['Resident', 'Tourist', 'Unkown']" label="Resident or Tourist" v-model="residentOrTourist"></v-select>

      <!-- Occupation -->
      <v-select :items="occupationOptions" label="Occupation" :value="occupationLocal" @change="changeOccupation($event)"></v-select>

      <!-- Occupation Other -->
      <transition name="slide-bottom">
        <v-text-field v-if="showOccupationOther" label="Occupation Other" type="text" v-model="occupation"></v-text-field>
      </transition>
    </v-form>
  </v-card>
</template>


<script>
import { AgeCategory1Options, AgeCategory2Options } from '@/views/IntakeFormPage/data/age-category-options.js';
import moment from 'moment';

import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      districtOptions: [
        'Noord',
        'Oranjestad West',
        'Oranjestad Oost',
        'Paradera',
        'Santa Cruz',
        'Savaneta/Pos Chiquito',
        'San Nicolas Noord',
        'San Nicolaas Zuid',
        'Unkown',
        'From Abroad'
      ],
      birthDateDialogOpen: false,
      required: false,
      ageCategory1Options: new AgeCategory1Options(),
      ageCategory2Options: new AgeCategory2Options(),
      occupationOptions: [
        'Health worker',
        'Working with animals',
        'Health laboratory',
        'Student',
        'Hospitality',
        'Not applicable',
        'Unknown',
        'Other'
      ],
      step1Valid: true,
      showOccupationOther: false,
      occupationLocal: '',
      occupationDataLoaded: false
    };
  },
  watch: {
    birthDateDialogOpen(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'));
    },
    birthDate(n) {
      if (n) {
        const age = this.getAge(n);
        this.setAgeCategories(age);
      }
    },
    occupation(n) {
      if (n && !this.occupationDataLoaded) this.setOccupation();
      if (n && this.occupationLocal.toLowerCase() != 'other') this.occupationLocal = n;
    }
  },
  computed: {
    ...mapGetters(['caseSelectedData']),
    district: {
      get() {
        return this.$store.state.intakeFormData.formData.district;
      },
      set(value) {
        this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', { key: 'district', value: value });
      }
    },
    sex: {
      get() {
        return this.$store.state.intakeFormData.formData.sex;
      },
      set(value) {
        this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', { key: 'sex', value: value });
      }
    },
    computedBirthDate() {
      return this.birthDate ? moment(this.birthDate).format('D-MMMM-YYYY') : '';
    },
    birthDate: {
      get() {
        return this.$store.state.intakeFormData.formData.birthDate
          ? moment(this.$store.state.intakeFormData.formData.birthDate, 'YYYY-MM-DD').format('YYYY-MM-DD')
          : null;
      },
      set(value) {
        this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', { key: 'birthDate', value: value + 'T13:00:00.000Z[UTC]' });
      }
    },
    age() {
      return this.$store.state.intakeFormData.formData.age;
    },
    ageCat1() {
      return this.$store.state.intakeFormData.formData.ageCat1;
    },
    ageCat2() {
      return this.$store.state.intakeFormData.formData.ageCat2;
    },
    residentOrTourist: {
      get() {
        return this.$store.state.intakeFormData.formData.residentOrTourist;
      },
      set(value) {
        this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', { key: 'residentOrTourist', value: value });
      }
    },
    occupation: {
      get() {
        return this.$store.state.intakeFormData.formData.occupation;
      },
      set(value) {
        this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', { key: 'occupation', value: value });
      }
    }
  },
  created() {
    this.setBirthDay();
  },
  methods: {
    saveDate() {
      this.birthDateDialogOpen = false;
    },
    setBirthDay() {
      if (this.caseSelectedData && this.caseSelectedData.birthDate) {
        this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', { key: 'birthDate', value: this.caseSelectedData.birthDate });
      }
    },
    getAge(dateString) {
      let today = new Date();
      let birthDate = new Date(dateString);
      let age = today.getFullYear() - birthDate.getFullYear();
      let m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    setAgeCategories(age) {
      //set age
      this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', { key: 'age', value: age });

      //cat1
      const ageCat1 = this.ageCategory1Options.filter((cat) => {
        if (age >= cat.min && age <= cat.max) return cat;
      });
      this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', { key: 'ageCat1', value: ageCat1[0].text });

      //cat2
      const ageCat2 = this.ageCategory2Options.filter((cat) => {
        if (age >= cat.min && age <= cat.max) return cat;
      });
      this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', { key: 'ageCat2', value: ageCat2[0].text });
    },
    changeOccupation(event) {
      this.occupationLocal = event;
      if (event.toLowerCase() != 'other') {
        this.showOccupationOther = false;
        this.occupation = event;
      } else if (event.toLowerCase() == 'other') {
        this.showOccupationOther = true;
        this.occupation = null;
      }
    },
    setOccupation() {
      this.occupationDataLoaded = true;
      let occupationNotOptions = true;
      this.occupationOptions.forEach((element) => {
        if (element == this.occupation) occupationNotOptions = false;
      });

      if (occupationNotOptions) {
        this.showOccupationOther = true;
        this.occupationLocal = 'Other';
      }
    }
  }
};
</script>

<style>
</style>