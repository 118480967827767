<template>
  <v-dialog :persistent="true" v-model="showSubmissionModal" max-width="500" scrollable>
    <v-card>
      <v-card-title class="headline primary white--text">Review Fields</v-card-title>

      <v-card-text>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Name</th>
                <th class="text-left">Value</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in data" :key="item + '-' + i">
                <td>{{ i }}</td>
                <td v-if="isDate(item)">{{ formatDate(item) }}</td>
                <td v-else>{{ item }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>

      <v-card-actions class="px-6 py-4" style="background: whitesmoke">
        <v-spacer></v-spacer>
        <v-btn text @click="closeModal()" class="mr-4" :disabled="submittingForm">Close</v-btn>
        <v-btn color="success" large :disabled="submittingForm" @click="submit()">
          Submit
          <v-icon right>mdi-send</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment';
export default {
  props: ['showSubmissionModal', 'data'],
  data() {
    return {
      submittingForm: false
    };
  },
  methods: {
    closeModal() {
      this.$parent.showSubmissionModal = false;
    },
    formatDate(d) {
      return moment(d, 'YYYY-MM-DD').format('DD-MMM-YYYY');
    },
    isDate(d) {
      if (d && typeof d === 'string' && d.includes('00Z[UTC]')) return true;
      else return false;
    },
    submit() {
      this.submittingForm = true;
      this.$store.commit('SET_SHOW_LOADER', { action: true, text: 'One moment please...' });

      const payload = {
        formData: this.$store.getters.intakeFormData,
        caseId: this.$route.params.caseId
      };

      this.$store.dispatch('submitIntakeForm', payload).then((data) => {
        this.submittingForm = false;
        this.$store.commit('SET_SHOW_LOADER', { action: false, text: null });
        if (data) {
          this.showSubmissionModal = true;
          this.$store.commit('SET_CURRENT_STEP_INTAKE_FORM', 1);
          this.$store.commit('RESET_INTAKE_FORM_DATA');
          this.$router.push('/account');
          let payload = {
            open: true,
            text: 'Intake form successfully updated',
            color: 'success'
          };
          this.$store.commit('SET_SNACKBAR', payload);
        }
      });
    }
  }
};
</script>

<style>
</style>