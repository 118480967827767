<template>
  <v-container class="intake-form-page" v-if="hasAccessToPage">
    <v-row justify="center">
      <v-col cols="12" lg="12" xl="8">
        <v-card class="elevation-1 bg-whitesmoke card-empty visitor-card">
          <v-list-item one-line class="pt-1">
            <v-list-item-content>
              <v-list-item-title class="subtitle-1 mb-1 primary--text font-weight-medium">
                <v-btn fab @click="resetFormAndGoBack()" class="me-2 mb-1" small elevation="2" color="primary">
                  <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
                INTAKE FORM
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-card-text class="bg-white text-area" style="background: #f9f9f9 !important">
            <IntakeFormWizard />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
  <NoAccessMessage v-else />
</template>

<script>
import SharedFunctions from '@/mixins/SharedFunctions.vue';
import NoAccessMessage from '@/components/generic/no-access-message.vue';
import IntakeFormWizard from '@/views/IntakeFormPage/components/IntakeFormWizard.vue';
import '@/views/IntakeFormPage/assets/intake-form-styles.scss';

export default {
  mixins: [SharedFunctions],
  components: {
    NoAccessMessage,
    IntakeFormWizard
  },
  data() {
    return {
      hasAccessToPage: false
    };
  },
  created() {
    this.hasAccessToPage = this.hasAccessToPageMixin();
    this.setCaseId();
    this.getIntakeFormData();
  },
  beforeDestroy() {
    this.$store.commit('SET_CURRENT_STEP_INTAKE_FORM', 1);
    this.$store.commit('RESET_INTAKE_FORM_DATA');
  },
  methods: {
    setCaseId() {
      if (this.$route.params.caseId) this.$store.commit('SET_CASE_ID_INTAKE_FORM', this.$route.params.caseId);
    },
    resetForm() {
      this.$store.commit('SET_CURRENT_STEP_INTAKE_FORM', 1);
      this.$store.commit('RESET_INTAKE_FORM_DATA');
    },
    resetFormAndGoBack() {
      this.resetForm();
      this.$router.go(-1);
    },
    getIntakeFormData() {
      this.$store.commit('SET_SHOW_LOADER', { action: true, text: 'One moment...' });
      this.$store.dispatch('getIntakeFormData', this.$route.params.caseId).then((data) => {
        if (data) this.prefillFormData(data);
        this.$store.commit('SET_SHOW_LOADER', { action: false, text: null });
      });
    },
    prefillFormData(data) {
      Object.keys(data).forEach((key) => {
        let value = data[key];
        if (key != 'id') this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', { key: key, value: value });
      });
    }
  }
};
</script>

<style>
</style>