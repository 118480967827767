<template>
<v-card class="step step2 px-8 pt-4 pb-8 my-4">
    <v-form v-model="step2Valid">

        <!-- Onset date -->
        <v-menu
            v-model="onsetDateMenuOpen"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            min-width="290px"
        >
            <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="computedOnsetDate"
                label="Onset Date"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
            ></v-text-field>
            </template>
            <v-date-picker v-model="onsetDate" @input="onsetDateMenuOpen = false"></v-date-picker>
        </v-menu>

        <!-- EPI_week ? -->
        <v-text-field
            label="Epi_week"
            type="text"
            v-model="epiWeek"
        ></v-text-field>

        <!-- health questions group 1 -->
        <v-radio-group v-model="fever38Higher">
            <template v-slot:label><div class="font-weight-medium">Fever higher than 38 degrees</div></template>
            <v-radio :label="option" :value="option" v-for="(option, n) in yesNoOtherOptions" :key="option.key + '-'  + n"/>
        </v-radio-group>
        <v-text-field
            label="Max Temperature"
            type="number"
            append-outer-icon="mdi-temperature-celsius"
            v-model="maxTemp"
        ></v-text-field>
        <v-radio-group v-model="cough">
            <template v-slot:label><div class="font-weight-medium">Cough</div></template>
            <v-radio :label="option" :value="option" v-for="(option, n) in yesNoOtherOptions" :key="option.key + '-'  + n"/>
        </v-radio-group>
        <v-radio-group v-model="shortnessBreath">
            <template v-slot:label><div class="font-weight-medium">Shortness of Breath</div></template>
            <v-radio :label="option" :value="option" v-for="(option, n) in yesNoOtherOptions" :key="option.key + '-'  + n"/>
        </v-radio-group>
        <v-radio-group v-model="otherPresence">
            <template v-slot:label><div class="font-weight-medium">Other Presence</div></template>
            <v-radio :label="option" :value="option" v-for="(option, n) in yesNoOtherOptions" :key="option.key + '-'  + n"/>
        </v-radio-group>
        <v-radio-group v-model="fatigue">
            <template v-slot:label><div class="font-weight-medium">Fatigue</div></template>
            <v-radio :label="option" :value="option" v-for="(option, n) in yesNoOtherOptions" :key="option.key + '-'  + n"/>
        </v-radio-group>
        <v-radio-group v-model="soreThroat">
            <template v-slot:label><div class="font-weight-medium">Sore Throat</div></template>
            <v-radio :label="option" :value="option" v-for="(option, n) in yesNoOtherOptions" :key="option.key + '-'  + n"/>
        </v-radio-group>
        <v-radio-group v-model="runnyNose">
            <template v-slot:label><div class="font-weight-medium">Runny Nose</div></template>
            <v-radio :label="option" :value="option" v-for="(option, n) in yesNoOtherOptions" :key="option.key + '-'  + n"/>
        </v-radio-group>
        <v-radio-group v-model="vomiting">
            <template v-slot:label><div class="font-weight-medium">Vomiting</div></template>
            <v-radio :label="option" :value="option" v-for="(option, n) in yesNoOtherOptions" :key="option.key + '-'  + n"/>
        </v-radio-group>
        <v-radio-group v-model="nausea">
            <template v-slot:label><div class="font-weight-medium">Nausea</div></template>
            <v-radio :label="option" :value="option" v-for="(option, n) in yesNoOtherOptions" :key="option.key + '-'  + n"/>
        </v-radio-group>
        <v-radio-group v-model="diarrhea">
            <template v-slot:label><div class="font-weight-medium">Diarrhea</div></template>
            <v-radio :label="option" :value="option" v-for="(option, n) in yesNoOtherOptions" :key="option.key + '-'  + n"/>
        </v-radio-group>
        <v-radio-group v-model="headache">
            <template v-slot:label><div class="font-weight-medium">Headache</div></template>
            <v-radio :label="option" :value="option" v-for="(option, n) in yesNoOtherOptions" :key="option.key + '-'  + n"/>
        </v-radio-group>
        <v-radio-group v-model="myalgia">
            <template v-slot:label><div class="font-weight-medium">Myalgia</div></template>
            <v-radio :label="option" :value="option" v-for="(option, n) in yesNoOtherOptions" :key="option.key + '-'  + n"/>
        </v-radio-group>
        <v-radio-group v-model="anosmia">
            <template v-slot:label><div class="font-weight-medium">Anosmia</div></template>
            <v-radio :label="option" :value="option" v-for="(option, n) in yesNoOtherOptions" :key="option.key + '-'  + n"/>
        </v-radio-group>
        <v-text-field
            label="Other Symptoms"
            type="text"
            v-model="otherSymptoms"
        ></v-text-field>
        <v-radio-group v-model="presenceChronicHealthConditions">
            <template v-slot:label><div class="font-weight-medium">Presence Chronic Health Conditions</div></template>
            <v-radio :label="option" :value="option" v-for="(option, n) in yesNoOtherOptions" :key="option.key + '-'  + n"/>
        </v-radio-group>
        <v-radio-group v-model="cvd">
            <template v-slot:label><div class="font-weight-medium">CVD</div></template>
            <v-radio :label="option" :value="option" v-for="(option, n) in yesNoOtherOptions" :key="option.key + '-'  + n"/>
        </v-radio-group>
        <v-radio-group v-model="diabetes">
            <template v-slot:label><div class="font-weight-medium">Diabetes</div></template>
            <v-radio :label="option" :value="option" v-for="(option, n) in yesNoOtherOptions" :key="option.key + '-'  + n"/>
        </v-radio-group>
        <v-radio-group v-model="chronicRespDisease">
            <template v-slot:label><div class="font-weight-medium">Chronic Resperatory Disease</div></template>
            <v-radio :label="option" :value="option" v-for="(option, n) in yesNoOtherOptions" :key="option.key + '-'  + n"/>
        </v-radio-group>
        <v-radio-group v-model="highBloodPressure">
            <template v-slot:label><div class="font-weight-medium">High Blood Pressure</div></template>
            <v-radio :label="option" :value="option" v-for="(option, n) in yesNoOtherOptions" :key="option.key + '-'  + n"/>
        </v-radio-group>
        <v-radio-group v-model="cancer">
            <template v-slot:label><div class="font-weight-medium">Cancer</div></template>
            <v-radio :label="option" :value="option" v-for="(option, n) in yesNoOtherOptions" :key="option.key + '-'  + n"/>
        </v-radio-group>
        <v-radio-group v-model="compormisedImmune">
            <template v-slot:label><div class="font-weight-medium">Compromised Immune</div></template>
            <v-radio :label="option" :value="option" v-for="(option, n) in yesNoOtherOptions" :key="option.key + '-'  + n"/>
        </v-radio-group>
        <v-radio-group v-model="otherHealthCondition">
            <template v-slot:label><div class="font-weight-medium">Other Health Condition</div></template>
            <v-radio :label="option" :value="option" v-for="(option, n) in yesNoOtherOptions" :key="option.key + '-'  + n"/>
        </v-radio-group>
        <v-text-field
            label="Other Specify"
            type="text"
            v-model="otherSpecify"
        ></v-text-field>

    </v-form>
</v-card>
  
</template>


<script>
import moment from 'moment';
import { YesNoOtherOptions } from '@/views/IntakeFormPage/data/yes-no-others-options';

export default {
    components: {

    },
    data() {
        return {
            onsetDateMenuOpen: false,
            required: false,
            yesNoOtherOptions: new YesNoOtherOptions(),
            step2Valid: false
        }
    },
    watch: {

    },
    computed: {
        computedOnsetDate () {
            return this.onsetDate ? moment(this.onsetDate, 'YYYY-MM-DD').format('D-MMMM-YYYY') : null
        },
        onsetDate: {
            get() {
                return this.$store.state.intakeFormData.formData.onsetDate ? 
                    moment(this.$store.state.intakeFormData.formData.onsetDate,'YYYY-MM-DD').format('YYYY-MM-DD'): null;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'onsetDate', value: value + 'T01:00:00.000Z[UTC]' });
            }
        },
        epiWeek: {
            get() {
                return this.$store.state.intakeFormData.formData.epiWeek;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'epiWeek', value: value});
            }
        },
        fever38Higher: {
            get() {
                return this.$store.state.intakeFormData.formData.fever38Higher;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'fever38Higher', value: value});
            }
        },
        maxTemp: {
            get() {
                return this.$store.state.intakeFormData.formData.maxTemp;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'maxTemp', value: value});
            }
        },
        cough: {
            get() {
                return this.$store.state.intakeFormData.formData.cough;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'cough', value: value});
            }
        },
        shortnessBreath: {
            get() {
                return this.$store.state.intakeFormData.formData.shortnessBreath;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'shortnessBreath', value: value});
            }
        },
        otherPresence: {
            get() {
                return this.$store.state.intakeFormData.formData.otherPresence;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'otherPresence', value: value});
            }
        },
        fatigue: {
            get() {
                return this.$store.state.intakeFormData.formData.fatigue;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'fatigue', value: value});
            }
        },
        soreThroat: {
            get() {
                return this.$store.state.intakeFormData.formData.soreThroat;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'soreThroat', value: value});
            }
        },
        runnyNose: {
            get() {
                return this.$store.state.intakeFormData.formData.runnyNose;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'runnyNose', value: value});
            }
        },
        vomiting: {
            get() {
                return this.$store.state.intakeFormData.formData.vomiting;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'vomiting', value: value});
            }
        },
        nausea: {
            get() {
                return this.$store.state.intakeFormData.formData.nausea;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'nausea', value: value});
            }
        },
        diarrhea: {
            get() {
                return this.$store.state.intakeFormData.formData.diarrhea;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'diarrhea', value: value});
            }
        },
        headache: {
            get() {
                return this.$store.state.intakeFormData.formData.headache;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'headache', value: value});
            }
        },
        myalgia: {
            get() {
                return this.$store.state.intakeFormData.formData.myalgia;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'myalgia', value: value});
            }
        },
        anosmia: {
            get() {
                return this.$store.state.intakeFormData.formData.anosmia;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'anosmia', value: value});
            }
        },
        otherSymptoms: {
            get() {
                return this.$store.state.intakeFormData.formData.otherSymptoms;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'otherSymptoms', value: value});
            }
        },
        presenceChronicHealthConditions: {
            get() {
                return this.$store.state.intakeFormData.formData.presenceChronicHealthConditions;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'presenceChronicHealthConditions', value: value});
            }
        },
        cvd: {
            get() {
                return this.$store.state.intakeFormData.formData.cvd;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'cvd', value: value});
            }
        },
        diabetes: {
            get() {
                return this.$store.state.intakeFormData.formData.diabetes;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'diabetes', value: value});
            }
        },
        chronicRespDisease: {
            get() {
                return this.$store.state.intakeFormData.formData.chronicRespDisease;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'chronicRespDisease', value: value});
            }
        },
        highBloodPressure: {
            get() {
                return this.$store.state.intakeFormData.formData.highBloodPressure;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'highBloodPressure', value: value});
            }
        },
        cancer: {
            get() {
                return this.$store.state.intakeFormData.formData.cancer;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'cancer', value: value});
            }
        },
        compormisedImmune: {
            get() {
                return this.$store.state.intakeFormData.formData.compormisedImmune;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'compormisedImmune', value: value});
            }
        },
        otherHealthCondition: {
            get() {
                return this.$store.state.intakeFormData.formData.otherHealthCondition;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'otherHealthCondition', value: value});
            }
        },
        otherSpecify: {
            get() {
                return this.$store.state.intakeFormData.formData.otherSpecify;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'otherSpecify', value: value});
            }
        },
    },
    methods: {
    }

}
</script>

<style>

</style>