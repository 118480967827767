<template>
<v-card class="step step4 px-8 pt-4 pb-8 my-4">
    <v-form v-model="step4Valid">

        <!-- Travel History -->
        <v-radio-group v-model="travelHistory">
            <template v-slot:label><div class="font-weight-medium">Travel History</div></template>
            <v-radio :label="option" :value="option" v-for="(option, n) in yesNoOtherOptions" :key="option.key + '-'  + n"/>
        </v-radio-group>

        <!-- country 1 -->
        <v-autocomplete
            :items="countries"
            color='primary'
            clearable
            v-model="country1"
            label="Country 1">
        </v-autocomplete>

        <!-- contactConfirmedCase14Days -->
        <v-radio-group v-model="contactConfirmedCase14Days">
            <template v-slot:label><div class="font-weight-medium">Contact Confirmed Case 14 Days</div></template>
            <v-radio :label="option" :value="option" v-for="(option, n) in yesNoOtherOptions" :key="option.key + '-'  + n"/>
        </v-radio-group>

        <!-- Links -->
        <v-text-field
            label="Links"
            type="text"
            v-model="links"
        ></v-text-field>

        <!-- primairSecundairNumber -->
        <v-text-field
            label="Primary Secundary Number"
            type="text"
            v-model="primarySecundaryNumber"
        ></v-text-field>

        <!-- nameLab -->
        <v-select
          :items="nameLabOptions"
          label="Lab Name"
          v-model="nameLab"
        ></v-select>

        <!-- swap type -->
        <v-select
          :items="typeSwabTakenOptions"
          label="Swap Type Taken"
          v-model="typeSwabTaken"
        ></v-select>

        <!-- dateSwab1 -->
        <v-menu
            v-model="dateSwab1MenuOpen"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            min-width="290px">
            <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="computedDateSwab1"
                label="Date Swap 1"
                readonly
                append-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
            ></v-text-field>
            </template>
            <v-date-picker v-model="dateSwab1" @input="dateSwab1MenuOpen = false"></v-date-picker>
        </v-menu>

        <!-- Origin -->
        <v-select
          :items="originOptions"
          label="Origin"
          v-model="origin"
        ></v-select>

        <!-- fever38Higher2 -->
        <v-radio-group v-model="fever38Higher2">
            <template v-slot:label><div class="font-weight-medium">Fever higher than 38 degrees 2</div></template>
            <v-radio :label="option" :value="option" v-for="(option, n) in yesNoOtherOptions" :key="option.key + '-'  + n"/>
        </v-radio-group>

        <!-- maxTemp3 -->
        <v-radio-group v-model="maxTemp3">
            <template v-slot:label><div class="font-weight-medium">Maximum temperature 3</div></template>
            <v-radio :label="option" :value="option" v-for="(option, n) in yesNoOtherOptions" :key="option.key + '-'  + n"/>
        </v-radio-group>

        <!-- cough4 -->
        <v-radio-group v-model="cough4">
            <template v-slot:label><div class="font-weight-medium">Cough 4</div></template>
            <v-radio :label="option" :value="option" v-for="(option, n) in yesNoOtherOptions" :key="option.key + '-'  + n"/>
        </v-radio-group>

        <!-- shortnessBreath5 -->
        <v-radio-group v-model="shortnessBreath5">
            <template v-slot:label><div class="font-weight-medium">Shortness of Breath 5</div></template>
            <v-radio :label="option" :value="option" v-for="(option, n) in yesNoOtherOptions" :key="option.key + '-'  + n"/>
        </v-radio-group>

        <!-- otherPresence6 -->
        <v-radio-group v-model="otherPresence6">
            <template v-slot:label><div class="font-weight-medium">Other Presence 6</div></template>
            <v-radio :label="option" :value="option" v-for="(option, n) in yesNoOtherOptions" :key="option.key + '-'  + n"/>
        </v-radio-group>

        <!-- fatigue7 -->
        <v-radio-group v-model="fatigue7">
            <template v-slot:label><div class="font-weight-medium">Fatigue 7</div></template>
            <v-radio :label="option" :value="option" v-for="(option, n) in yesNoOtherOptions" :key="option.key + '-'  + n"/>
        </v-radio-group>

        <!-- soreThroat8 -->
        <v-radio-group v-model="soreThroat8">
            <template v-slot:label><div class="font-weight-medium">Sore Throat 8</div></template>
            <v-radio :label="option" :value="option" v-for="(option, n) in yesNoOtherOptions" :key="option.key + '-'  + n"/>
        </v-radio-group>

        <!-- runnyNose9 -->
        <v-radio-group v-model="runnyNose9">
            <template v-slot:label><div class="font-weight-medium">Runny Nose 9</div></template>
            <v-radio :label="option" :value="option" v-for="(option, n) in yesNoOtherOptions" :key="option.key + '-'  + n"/>
        </v-radio-group>

        <!-- vomiting10 -->
        <v-radio-group v-model="vomiting10">
            <template v-slot:label><div class="font-weight-medium">Vomiting 10</div></template>
            <v-radio :label="option" :value="option" v-for="(option, n) in yesNoOtherOptions" :key="option.key + '-'  + n"/>
        </v-radio-group>

        <!-- nausea11 -->
        <v-radio-group v-model="nausea11">
            <template v-slot:label><div class="font-weight-medium">Nausea 11</div></template>
            <v-radio :label="option" :value="option" v-for="(option, n) in yesNoOtherOptions" :key="option.key + '-'  + n"/>
        </v-radio-group>

        <!-- diarrhea12 -->
        <v-radio-group v-model="diarrhea12">
            <template v-slot:label><div class="font-weight-medium">Diarrhea 12</div></template>
            <v-radio :label="option" :value="option" v-for="(option, n) in yesNoOtherOptions" :key="option.key + '-'  + n"/>
        </v-radio-group>

        <!-- headache13 -->
        <v-radio-group v-model="headache13">
            <template v-slot:label><div class="font-weight-medium">Headache 13</div></template>
            <v-radio :label="option" :value="option" v-for="(option, n) in yesNoOtherOptions" :key="option.key + '-'  + n"/>
        </v-radio-group>

        <!-- myalgia14 -->
        <v-radio-group v-model="myalgia14">
            <template v-slot:label><div class="font-weight-medium">Myalgia 14</div></template>
            <v-radio :label="option" :value="option" v-for="(option, n) in yesNoOtherOptions" :key="option.key + '-'  + n"/>
        </v-radio-group>

        <!-- anosmia15 -->
        <v-radio-group v-model="anosmia15">
            <template v-slot:label><div class="font-weight-medium">Anosmia 15</div></template>
            <v-radio :label="option" :value="option" v-for="(option, n) in yesNoOtherOptions" :key="option.key + '-'  + n"/>
        </v-radio-group>

        <!-- otherSymptoms16 -->
        <v-text-field
            label="Other Symptoms 16"
            type="text"
            v-model="otherSymptoms16"
        ></v-text-field>

        <!-- fever38Higher3 -->
        <v-radio-group v-model="fever38Higher3">
            <template v-slot:label><div class="font-weight-medium">Fever higher than 38 degrees 3</div></template>
            <v-radio :label="option" :value="option" v-for="(option, n) in yesNoOtherOptions" :key="option.key + '-'  + n"/>
        </v-radio-group>

        <!-- maxTemp4 -->
        <v-radio-group v-model="maxTemp4">
            <template v-slot:label><div class="font-weight-medium">Maximum temperature 4</div></template>
            <v-radio :label="option" :value="option" v-for="(option, n) in yesNoOtherOptions" :key="option.key + '-'  + n"/>
        </v-radio-group>

        <!-- cough5 -->
        <v-radio-group v-model="cough5">
            <template v-slot:label><div class="font-weight-medium">Cough 5</div></template>
            <v-radio :label="option" :value="option" v-for="(option, n) in yesNoOtherOptions" :key="option.key + '-'  + n"/>
        </v-radio-group>

        <!-- shortnessBreath6 -->
        <v-radio-group v-model="shortnessBreath6">
            <template v-slot:label><div class="font-weight-medium">Shortness of Breath 6</div></template>
            <v-radio :label="option" :value="option" v-for="(option, n) in yesNoOtherOptions" :key="option.key + '-'  + n"/>
        </v-radio-group>

        <!-- otherPresence7 -->
        <v-radio-group v-model="otherPresence7">
            <template v-slot:label><div class="font-weight-medium">Other Presence 7</div></template>
            <v-radio :label="option" :value="option" v-for="(option, n) in yesNoOtherOptions" :key="option.key + '-'  + n"/>
        </v-radio-group>

        <!-- fatigue8 -->
        <v-radio-group v-model="fatigue8">
            <template v-slot:label><div class="font-weight-medium">Fatigue 8</div></template>
            <v-radio :label="option" :value="option" v-for="(option, n) in yesNoOtherOptions" :key="option.key + '-'  + n"/>
        </v-radio-group>

        <!-- soreThroat9 -->
        <v-radio-group v-model="soreThroat9">
            <template v-slot:label><div class="font-weight-medium">Sore Throat 9</div></template>
            <v-radio :label="option" :value="option" v-for="(option, n) in yesNoOtherOptions" :key="option.key + '-'  + n"/>
        </v-radio-group>

        <!-- runnyNose10 -->
        <v-radio-group v-model="runnyNose10">
            <template v-slot:label><div class="font-weight-medium">Runny Nose 10</div></template>
            <v-radio :label="option" :value="option" v-for="(option, n) in yesNoOtherOptions" :key="option.key + '-'  + n"/>
        </v-radio-group>

        <!-- vomiting11 -->
        <v-radio-group v-model="vomiting11">
            <template v-slot:label><div class="font-weight-medium">Vomiting 11</div></template>
            <v-radio :label="option" :value="option" v-for="(option, n) in yesNoOtherOptions" :key="option.key + '-'  + n"/>
        </v-radio-group>

        <!-- nausea12 -->
        <v-radio-group v-model="nausea12">
            <template v-slot:label><div class="font-weight-medium">Nasea 12</div></template>
            <v-radio :label="option" :value="option" v-for="(option, n) in yesNoOtherOptions" :key="option.key + '-'  + n"/>
        </v-radio-group>

        <!-- diarrhea13 -->
        <v-radio-group v-model="diarrhea13">
            <template v-slot:label><div class="font-weight-medium">Diarrhea 13</div></template>
            <v-radio :label="option" :value="option" v-for="(option, n) in yesNoOtherOptions" :key="option.key + '-'  + n"/>
        </v-radio-group>

        <!-- headache14 -->
        <v-radio-group v-model="headache14">
            <template v-slot:label><div class="font-weight-medium">Headache 14</div></template>
            <v-radio :label="option" :value="option" v-for="(option, n) in yesNoOtherOptions" :key="option.key + '-'  + n"/>
        </v-radio-group>

        <!-- myalgia15 -->
        <v-radio-group v-model="myalgia15">
            <template v-slot:label><div class="font-weight-medium">Myalgia 15</div></template>
            <v-radio :label="option" :value="option" v-for="(option, n) in yesNoOtherOptions" :key="option.key + '-'  + n"/>
        </v-radio-group>

        <!-- anosmia16 -->
        <v-radio-group v-model="anosmia16">
            <template v-slot:label><div class="font-weight-medium">Anosmia 16</div></template>
            <v-radio :label="option" :value="option" v-for="(option, n) in yesNoOtherOptions" :key="option.key + '-'  + n"/>
        </v-radio-group>

        <!-- otherSymptoms17 -->
        <v-text-field
            label="Other Symptoms 17"
            type="text"
            v-model="otherSymptoms17"
        ></v-text-field>

        <!-- socialActivityComment -->
        <v-textarea
            label="Social Activity Comment"
            v-model="socialActivityComment"
        ></v-textarea>

    </v-form>
</v-card>
  
</template>


<script>
import moment from 'moment';
import { YesNoOtherOptions } from '@/views/IntakeFormPage/data/yes-no-others-options';

import listOfCountriesJson from '@/store/data/list-of-countries.json';

export default {
    components: {
        
    },
    data() {
        return {
            yesNoOtherOptions: new YesNoOtherOptions(),
            step4Valid: false,
            countries: null,
            dateSwab1MenuOpen: false,
            nameLabOptions: ['F.S.L.M.A.', 'I.L. Labatory Familiar', 'Noord Lab Center', 'Alpha Interlab NV','Service Lab Xpert','Laboratorio di Servicio','Pending', 'Not Applicable', 'Unkown'],
            typeSwabTakenOptions: ['Nasal Swab', 'Throat Swab', 'Nasopharyngeal Swab', 'Other', 'Not Applicable'],
            originOptions: ['Travel Related', 'Travel with Contact', 'Contact Confirmed','Unknown', 'Other']
        }
    },
    computed: {
        travelHistory: {
            get() {
                return this.$store.state.intakeFormData.formData.travelHistory;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'travelHistory', value: value});
            }
        },
        country1: {
            get() {
                return this.$store.state.intakeFormData.formData.country1;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'country1', value: value});
            }
        },
        contactConfirmedCase14Days: {
            get() {
                return this.$store.state.intakeFormData.formData.contactConfirmedCase14Days;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'contactConfirmedCase14Days', value: value});
            }
        },
        links: {
            get() {
                return this.$store.state.intakeFormData.formData.links;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'links', value: value});
            }
        },
        primarySecundaryNumber: {
            get() {
                return this.$store.state.intakeFormData.formData.primarySecundaryNumber;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'primarySecundaryNumber', value: value});
            }
        },
        nameLab: {
            get() {
                return this.$store.state.intakeFormData.formData.nameLab;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'nameLab', value: value});
            }
        },
        typeSwabTaken: {
            get() {
                return this.$store.state.intakeFormData.formData.typeSwabTaken;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'typeSwabTaken', value: value});
            }
        },
        computedDateSwab1 () {
            return this.dateSwab1 ? moment(this.dateSwab1).format('D-MMMM-YYYY') : ''
        },
        dateSwab1: {
            get() {
                return this.$store.state.intakeFormData.formData.dateSwab1 ? 
                    moment(this.$store.state.intakeFormData.formData.dateSwab1,'YYYY-MM-DD').format('YYYY-MM-DD'): null;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'dateSwab1', value: value + 'T01:00:00.000Z[UTC]' });
            }
        },
        origin: {
            get() {
                return this.$store.state.intakeFormData.formData.origin;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'origin', value: value});
            }
        },
        fever38Higher2: {
            get() {
                return this.$store.state.intakeFormData.formData.fever38Higher2;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'fever38Higher2', value: value});
            }
        },
        maxTemp3: {
            get() {
                return this.$store.state.intakeFormData.formData.maxTemp3;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'maxTemp3', value: value});
            }
        },
        cough4: {
            get() {
                return this.$store.state.intakeFormData.formData.cough4;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'cough4', value: value});
            }
        },
        shortnessBreath5: {
            get() {
                return this.$store.state.intakeFormData.formData.shortnessBreath5;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'shortnessBreath5', value: value});
            }
        },
        otherPresence6: {
            get() {
                return this.$store.state.intakeFormData.formData.otherPresence6;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'otherPresence6', value: value});
            }
        },
        fatigue7: {
            get() {
                return this.$store.state.intakeFormData.formData.fatigue7;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'fatigue7', value: value});
            }
        },
        soreThroat8: {
            get() {
                return this.$store.state.intakeFormData.formData.soreThroat8;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'soreThroat8', value: value});
            }
        },
        runnyNose9: {
            get() {
                return this.$store.state.intakeFormData.formData.runnyNose9;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'runnyNose9', value: value});
            }
        },
        vomiting10: {
            get() {
                return this.$store.state.intakeFormData.formData.vomiting10;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'vomiting10', value: value});
            }
        },
        nausea11: {
            get() {
                return this.$store.state.intakeFormData.formData.nausea11;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'nausea11', value: value});
            }
        },
        diarrhea12: {
            get() {
                return this.$store.state.intakeFormData.formData.diarrhea12;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'diarrhea12', value: value});
            }
        },
        headache13: {
            get() {
                return this.$store.state.intakeFormData.formData.headache13;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'headache13', value: value});
            }
        },
        myalgia14: {
            get() {
                return this.$store.state.intakeFormData.formData.myalgia14;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'myalgia14', value: value});
            }
        },
        anosmia15: {
            get() {
                return this.$store.state.intakeFormData.formData.anosmia15;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'anosmia15', value: value});
            }
        },
        otherSymptoms16: {
            get() {
                return this.$store.state.intakeFormData.formData.otherSymptoms16;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'otherSymptoms16', value: value});
            }
        },
        fever38Higher3: {
            get() {
                return this.$store.state.intakeFormData.formData.fever38Higher3;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'fever38Higher3', value: value});
            }
        },
        maxTemp4: {
            get() {
                return this.$store.state.intakeFormData.formData.maxTemp4;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'maxTemp4', value: value});
            }
        },
        cough5: {
            get() {
                return this.$store.state.intakeFormData.formData.cough5;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'cough5', value: value});
            }
        },
        shortnessBreath6: {
            get() {
                return this.$store.state.intakeFormData.formData.shortnessBreath6;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'shortnessBreath6', value: value});
            }
        },
        otherPresence7: {
            get() {
                return this.$store.state.intakeFormData.formData.otherPresence7;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'otherPresence7', value: value});
            }
        },
        fatigue8: {
            get() {
                return this.$store.state.intakeFormData.formData.fatigue8;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'fatigue8', value: value});
            }
        },
        soreThroat9: {
            get() {
                return this.$store.state.intakeFormData.formData.soreThroat9;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'soreThroat9', value: value});
            }
        },
        runnyNose10: {
            get() {
                return this.$store.state.intakeFormData.formData.runnyNose10;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'runnyNose10', value: value});
            }
        },
        vomiting11: {
            get() {
                return this.$store.state.intakeFormData.formData.vomiting11;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'vomiting11', value: value});
            }
        },
        nausea12: {
            get() {
                return this.$store.state.intakeFormData.formData.nausea12;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'nausea12', value: value});
            }
        },
        diarrhea13: {
            get() {
                return this.$store.state.intakeFormData.formData.diarrhea13;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'diarrhea13', value: value});
            }
        },
        headache14: {
            get() {
                return this.$store.state.intakeFormData.formData.headache14;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'headache14', value: value});
            }
        },
        myalgia15: {
            get() {
                return this.$store.state.intakeFormData.formData.myalgia15;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'myalgia15', value: value});
            }
        },
        anosmia16: {
            get() {
                return this.$store.state.intakeFormData.formData.anosmia16;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'anosmia16', value: value});
            }
        },
        otherSymptoms17: {
            get() {
                return this.$store.state.intakeFormData.formData.otherSymptoms17;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'otherSymptoms17', value: value});
            }
        },
        socialActivityComment: {
            get() {
                return this.$store.state.intakeFormData.formData.socialActivityComment;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'socialActivityComment', value: value});
            }
        },
    },
    created() {
        this.createArrayOfListOfCountries(listOfCountriesJson);
    },
    methods: {
        createArrayOfListOfCountries(list) {
            const newList = list.map(countryObj => {
                const newList = {};
                newList['value'] = countryObj.name;
                newList['text'] = countryObj.name;
                return newList;
            });

            this.countries = newList;
            if(this.extraItems)
                this.countries.unshift(this.extraItems.name)
        },
    }

}
</script>

<style>

</style>