export class AgeCategory1Options {
    constructor() {
        return [
            {min: 0, max: 1, text: '<1 yr.'},
            {min: 1, max: 4, text: '1 - 4 yrs'},
            {min: 5, max: 14, text: '5 - 14 yrs'},
            {min: 15, max: 24, text: '15 - 24 yrs'},
            {min: 25, max: 34, text: '25 - 34 yrs'},
            {min: 35, max: 44, text: '35 - 44 yrs'},
            {min: 45, max: 54, text: '45 - 54 yrs'},
            {min: 55, max: 64, text: '55 - 64 yrs'},
            {min: 65, max: 74, text: '65 - 74 yrs'},
            {min: 75, max: 84, text: '75 - 84 yrs'},
            {min: 85, max: 95, text: '85 - 95 yrs'},
            {min: 95, max: 9999, text: '95+ yrs'},
        ]
    }
  }
export class AgeCategory2Options {
    constructor() {
        return [
            {min: 0, max: 1, text: '<1 yr.'},
            {min: 1, max: 4, text: '1 - 4 yrs'},
            {min: 5, max: 9, text: '5 - 9 yrs'},
            {min: 10, max: 14, text: '10 - 14 yrs'},
            {min: 15, max: 19, text: '15 - 19 yrs'},
            {min: 20, max: 24, text: '20 - 24 yrs'},
            {min: 25, max: 29, text: '25 - 29 yrs'},
            {min: 30, max: 34, text: '30 - 34 yrs'},
            {min: 35, max: 39, text: '35 - 39 yrs'},
            {min: 40, max: 44, text: '40 - 44 yrs'},
            {min: 45, max: 49, text: '45 - 49 yrs'},
            {min: 50, max: 54, text: '50 - 54 yrs'},
            {min: 55, max: 59, text: '55 - 59 yrs'},
            {min: 60, max: 64, text: '60 - 64 yrs'},
            {min: 65, max: 69, text: '65 - 69 yrs'},
            {min: 70, max: 74, text: '70 - 74 yrs'},
            {min: 75, max: 79, text: '75 - 79 yrs'},
            {min: 80, max: 84, text: '80 - 84 yrs'},
            {min: 85, max: 89, text: '85 - 89 yrs'},
            {min: 90, max: 94, text: '90 - 94 yrs'},
            {min: 95, max: 9999, text: '95+ yrs'}
        ]
    }
  }