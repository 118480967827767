<template>
<v-card class="step step3 px-8 pt-4 pb-8 my-4">
    <v-form v-model="step3Valid">

        <!-- Hospitalized -->
        <v-radio-group v-model="hospitalized">
            <template v-slot:label><div class="font-weight-medium">Hospitalized</div></template>
            <v-radio :label="option" :value="option" v-for="(option, n) in yesNoUnkOptions" :key="option.key + '-'  + n"/>
        </v-radio-group>

        <!-- date admin -->
        <v-menu
            v-model="dateAdminMenuOpen"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            min-width="290px">
            <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="computedDateAdmin"
                label="Admin Date"
                readonly
                append-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
            ></v-text-field>
            </template>
            <v-date-picker v-model="dateAdmin" @input="dateAdminMenuOpen = false"></v-date-picker>
        </v-menu>

        <!-- date discharge -->
        <v-menu
            v-model="dateDischargeMenuOpen"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            min-width="290px">
            <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="computedDateDischarge"
                label="Discharge Date"
                readonly
                append-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
            ></v-text-field>
            </template>
            <v-date-picker v-model="dateDischarg" @input="dateDischargeMenuOpen = false"></v-date-picker>
        </v-menu>

        <!-- date change hospital -->
        <v-menu
            v-model="dateChangeHospitalMenuOpen"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            min-width="290px">
            <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="computedDateChangeHospital"
                label="Change Hospital Date"
                readonly
                append-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
            ></v-text-field>
            </template>
            <v-date-picker v-model="dateChangeHospital" @input="dateChangeHospitalMenuOpen = false"></v-date-picker>
        </v-menu>

        <!-- Level of care -->
        <v-radio-group v-model="levelOfCare">
            <template v-slot:label><div class="font-weight-medium">Level of Care</div></template>
            <v-radio :label="option" :value="option" v-for="(option, n) in levelOfCareOptions" :key="option.key + '-'  + n"/>
        </v-radio-group>

        <!-- Ventilation -->
        <v-radio-group v-model="ventilation">
            <template v-slot:label><div class="font-weight-medium">Ventilation</div></template>
            <v-radio :label="option" :value="option" v-for="(option, n) in yesNoOtherOptions" :key="option.key + '-'  + n"/>
        </v-radio-group>

        <!-- LevelOfCare2 -->
        <v-radio-group v-model="levelOfCare2">
            <template v-slot:label><div class="font-weight-medium">Level of Care 2</div></template>
            <v-radio :label="option" :value="option" v-for="(option, n) in levelOfCareOptions" :key="option.key + '-'  + n"/>
        </v-radio-group>

        <!-- comorbidity -->
        <v-radio-group v-model="comorbidity">
            <template v-slot:label><div class="font-weight-medium">Comorbidity</div></template>
            <v-radio :label="option" :value="option" v-for="(option, n) in yesNoOtherOptions" :key="option.key + '-'  + n"/>
        </v-radio-group>

        <!-- totDaysHoh -->
        <v-text-field
            label="Total days HOH"
            type="number"
            v-model="totDaysHoh"
        ></v-text-field>

        <!-- outcomeHospital -->
        <v-radio-group v-model="outcomeHospital">
            <template v-slot:label><div class="font-weight-medium">Outcome Hospital</div></template>
            <v-radio :label="option" :value="option" v-for="(option, n) in outcomeHospitalOptions" :key="option.key + '-'  + n"/>
        </v-radio-group>

        <!-- status case -->
        <v-radio-group v-model="statusCase">
            <template v-slot:label><div class="font-weight-medium">Status Case</div></template>
            <v-radio :label="option" :value="option" v-for="(option, n) in statusCaseOptions" :key="option.key + '-'  + n"/>
        </v-radio-group>

        <!-- date isolation release -->
        <v-menu
            v-model="dateIsolReleaseMenuOpen"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            min-width="290px">
            <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="computedDateIsolRelease"
                label="Date Isolation Release"
                readonly
                append-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
            ></v-text-field>
            </template>
            <v-date-picker v-model="dateIsolRelease" @input="dateIsolReleaseMenuOpen = false"></v-date-picker>
        </v-menu>

        <!-- totDaysIso -->
        <v-text-field
            label="Total days Isolation"
            type="number"
            v-model="totDaysIso"
        ></v-text-field>

    </v-form>
</v-card>
  
</template>


<script>
import moment from 'moment';
import { YesNoOtherOptions } from '@/views/IntakeFormPage/data/yes-no-others-options';

export default {
    components: {

    },
    data() {
        return {
            dateAdminMenuOpen: false,
            dateDischargeMenuOpen: false,
            dateChangeHospitalMenuOpen: false,
            dateIsolReleaseMenuOpen: false,
            yesNoOtherOptions: new YesNoOtherOptions(),
            step3Valid: false,
            yesNoUnkOptions: ['No', 'Yes', 'Unkown'],
            levelOfCareOptions: ['ICU', 'Medium Care', 'General', 'Not Applicable', 'Unkown'],
            outcomeHospitalOptions: ['Survived', 'Recovered', 'Still sick', 'Died', 'Unkown', 'NA'],
            statusCaseOptions: ['Active', 'Recovered', 'Died'],
        }
    },
    computed: {
        hospitalized: {
            get() {
                return this.$store.state.intakeFormData.formData.hospitalized;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'hospitalized', value: value});
            }
        },
        birthDate: {
            get() {
                return this.$store.state.intakeFormData.formData.birthDate ? 
                    moment(this.$store.state.intakeFormData.formData.birthDate,'YYYY-MM-DD').format('YYYY-MM-DD'): null;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'birthDate', value: value + 'T13:00:00.000Z[UTC]' });
            }
        },
        levelOfCare: {
            get() {
                return this.$store.state.intakeFormData.formData.levelOfCare;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'levelOfCare', value: value});
            }
        },
        ventilation: {
            get() {
                return this.$store.state.intakeFormData.formData.ventilation;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'ventilation', value: value});
            }
        },
        levelOfCare2: {
            get() {
                return this.$store.state.intakeFormData.formData.levelOfCare2;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'levelOfCare2', value: value});
            }
        },
        comorbidity: {
            get() {
                return this.$store.state.intakeFormData.formData.comorbidity;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'comorbidity', value: value});
            }
        },
        totDaysHoh: {
            get() {
                return this.$store.state.intakeFormData.formData.totDaysHoh;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'totDaysHoh', value: value});
            }
        },
        outcomeHospital: {
            get() {
                return this.$store.state.intakeFormData.formData.outcomeHospital;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'outcomeHospital', value: value});
            }
        },
        computedDateAdmin () {
            return this.dateAdmin ? moment(this.dateAdmin).format('D-MMMM-YYYY') : ''
        },
        dateAdmin: {
            get() {
                return this.$store.state.intakeFormData.formData.dateAdmin ? 
                    moment(this.$store.state.intakeFormData.formData.dateAdmin,'YYYY-MM-DD').format('YYYY-MM-DD'): null;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'dateAdmin', value: value + 'T01:00:00.000Z[UTC]' });
            }
        },
        computedDateDischarge () {
            return this.dateDischarg ? moment(this.dateDischarg).format('D-MMMM-YYYY') : ''
        },
        dateDischarg: {
            get() {
                return this.$store.state.intakeFormData.formData.dateDischarg ? 
                    moment(this.$store.state.intakeFormData.formData.dateDischarg,'YYYY-MM-DD').format('YYYY-MM-DD'): null;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'dateDischarg', value: value + 'T01:00:00.000Z[UTC]' });
            }
        },
        computedDateChangeHospital () {
            return this.dateChangeHospital ? moment(this.dateChangeHospital).format('D-MMMM-YYYY') : ''
        },
        dateChangeHospital: {
            get() {
                return this.$store.state.intakeFormData.formData.dateChangeHospital ? 
                    moment(this.$store.state.intakeFormData.formData.dateChangeHospital,'YYYY-MM-DD').format('YYYY-MM-DD'): null;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'dateChangeHospital', value: value + 'T01:00:00.000Z[UTC]' });
            }
        },
        statusCase: {
            get() {
                return this.$store.state.intakeFormData.formData.statusCase;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'statusCase', value: value});
            }
        },
        computedDateIsolRelease () {
            return this.dateIsolRelease ? moment(this.dateIsolRelease).format('D-MMMM-YYYY') : ''
        },
        dateIsolRelease: {
            get() {
                return this.$store.state.intakeFormData.formData.dateIsolRelease ? 
                    moment(this.$store.state.intakeFormData.formData.dateIsolRelease,'YYYY-MM-DD').format('YYYY-MM-DD'): null;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'dateIsolRelease', value: value + 'T01:00:00.000Z[UTC]' });
            }
        },
        totDaysIso: {
            get() {
                return this.$store.state.intakeFormData.formData.totDaysIso;
            },
            set(value) {
                this.$store.commit('SET_INTAKE_FORM_DATA_ITEM', {key:'totDaysIso', value: value});
            }
        },
    }

}
</script>

<style>

</style>