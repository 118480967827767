<template>
  <div>
    <v-stepper v-model="currentStep" class="stepper-main" style="background:#f9f9f9 !important">
      <v-stepper-header>
        <v-stepper-step :complete="currentStep > 1" step="1" editable>Personal</v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="currentStep > 2" step="2" editable>Health</v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="currentStep > 3" step="3" editable>Hospitalization</v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="currentStep > 4" step="4" editable>Travel</v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1" >
          <Step1 v-if="currentStep == 1" />
        </v-stepper-content>
        <v-stepper-content step="2">
          <Step2 v-if="currentStep == 2" />
        </v-stepper-content>
        <v-stepper-content step="3">
          <Step3 v-if="currentStep == 3" />
        </v-stepper-content>
        <v-stepper-content step="4">
          <Step4 v-if="currentStep == 4" />
        </v-stepper-content>
      
      </v-stepper-items>
      <div class="wizard-buttons mt-8">
        <div class="spacer"></div>
        <div class="right-buttons">
          <v-btn text large  @click="prevStep()" v-if="currentStep > 1">
            <v-icon left>mdi-chevron-left</v-icon>
            Back
          </v-btn>
          <v-btn color="primary" @click="nextStep()" large class="ms-4" v-if="currentStep < 4">
            Next
            <v-icon right>mdi-chevron-right</v-icon>
          </v-btn>
          <v-btn color="primary" @click="lastNextStep()" large class="ms-4" v-if="currentStep == 4">
            Next
            <v-icon right>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </div>
    </v-stepper>
    <IntakeFormSubmissionModal :showSubmissionModal="showSubmissionModal" :data="$store.state.intakeFormData.formData"/>
  </div>
</template>

<script>
import Step1 from '@/views/IntakeFormPage/components/Step1.vue'
import Step2 from '@/views/IntakeFormPage/components/Step2.vue'
import Step3 from '@/views/IntakeFormPage/components/Step3.vue'
import Step4 from '@/views/IntakeFormPage/components/Step4.vue'
import IntakeFormSubmissionModal from '@/views/IntakeFormPage/components/IntakeFormSubmissionModal.vue'

export default {
  components: {
    Step1,
    Step2,
    Step3,
    Step4,
    IntakeFormSubmissionModal
  },
  data() {
      return {
        showSubmissionModal: false
      }
  },
  computed: {
    currentStep: {
        get() {
            return this.$store.state.intakeFormData.currentStep;
        },
        set(value) {
            this.$store.commit('SET_CURRENT_STEP_INTAKE_FORM', value);
        }
    },
  },
  methods: {
    nextStep() {
      document.documentElement.scrollTop = 0;
      this.$store.commit('SET_CURRENT_STEP_INTAKE_FORM', this.$store.state.intakeFormData.currentStep + 1);
    },
    prevStep() {
      document.documentElement.scrollTop = 0;
      this.$store.commit('SET_CURRENT_STEP_INTAKE_FORM', this.$store.state.intakeFormData.currentStep - 1);
    },
    lastNextStep() {
      this.showSubmissionModal = true;
    }
  }

}
</script>

<style lang="scss">
  .stepper-main {
    box-shadow:none !important;
    .v-stepper__header{
      box-shadow: none;
    }
  }
</style>